.split-screen-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.split-screen-left {
  overflow-y: hidden;
}

.split-screen-right {
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (max-width: 768px) {
  .split-screen-container {
    flex-direction: column;
    overflow: auto; /* Change to auto to allow scrolling */
    min-height: 100vh;
  }

  .split-screen-left,
  .split-screen-right {
    width: 100% !important;
    height: auto; /* Adjust height to content */
    overflow-y: visible; /* Allow internal scrollbar if needed */
  }
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
}

.success-message {
  color: var(--btn_green_dark2_color);
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
}

.tag-pill {
  display: inline-block;
  background-color: var(--btn_green_color);
  color: white;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 20px;
  position: relative;
}

.hovered {
  background-color: var(
    --btn_green_dark_color
  ); /* Adjust styling when hovered */
}

/* Desktop: Hide the "X" button by default */
.tag-pill .remove-button {
  position: absolute;
  top: -5px; /* Adjust the top position to give space */
  right: -5px; /* Adjust the right position to give space */
  background-color: var(--btn_green_color);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  display: none; /* Initially hide the button */
}

/* Desktop: Show the "X" button on hover when editing */
.tag-pill.editing:hover .remove-button {
  display: block;
}

/* Mobile: Hide the "X" button by default */
@media (max-width: 768px) {
  .tag-pill .remove-button {
    display: none;
  }

  /* Mobile: Always show the "X" button when editing */
  .tag-pill.editing .remove-button {
    display: block;
  }
}

/* UserDropdownMenu.css */

.user-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
}

.user-dropdown-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-dropdown-li {
  padding: 10px;
  width: 150px;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  font-family: "Poppins", sans-serif;
  color: black;
  transition: all 0.3s;
}

.user-dropdown-li:hover {
  color: var(--title_green_color);
  border-color: var(--title_green_color);
}

/***************** FOR ENGINEERS/RECRUITERS SECTION ******************/
.for-eng-signupimg-col,
.for-eng-img-col {
  display: flex;
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
}

.for-eng-titleimg {
  height: 60px;
}

.joincom-titleimg {
  border-radius: 20px;
}

.for-eng-img {
  width: 35vw;
  max-width: 100%; /* Ensure the image does not overflow its container */
  height: auto; /* Maintain aspect ratio */
}

.for-eng-p {
  color: var(--title_blue_color);
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.for-eng-btn {
  margin-top: 50px;
}

/* Reverse columns for small screens */
.for-rec-reverse-colum-sm {
  flex-direction: row;
}

.clickable-image {
  display: inline-block; /* Aligns to the size of its content */
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
}

/*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
*/
@media (max-width: 1024px) {
  .for-eng-img {
    width: 100%;
    margin-bottom: 80px;
  }

  /* Reverse columns for sm0all screens */
  .for-rec-reverse-colum-sm {
    flex-direction: column-reverse;
  }
}

/**********************************************************/

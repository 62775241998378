.signupform-main-container {
  height: auto;
}

.signupform-title {
  padding-top: 150px;
  padding-bottom: 30px;
}

.signupform-title2 {
  padding-top: 50px;
  padding-bottom: 30px;
}

.left-side-container {
  height: 100%;
  margin: 0 60px;
}

.left-top-row {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.left-bottom-row {
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-bottom-col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.right-side-container {
  height: auto;
  padding: 0 80px;
  margin: 0 !important;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  min-height: 100vh;
}

.right-top-row {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  overflow-x: hidden;
}

.right-top-content-container {
  flex: 1; /* Allow the content to expand */
  min-height: 80vh;
}

.right-bottom-row {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-bottom-col-left {
  display: flex;
  justify-content: flex-start;
}

.right-bottom-col-right {
  display: flex;
  justify-content: flex-end;
}

.tags-pills {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .signupform-title {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .left-side-container {
    height: auto;
    margin: 0 10px;
  }

  .left-bottom-row {
    min-height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-top-row {
    margin-top: 150px;
    height: auto;
  }

  .right-side-container {
    height: 100%;
    padding: 0 5px;
    margin: 0 20px;
  }

  .right-top-row {
    height: auto;
    min-height: auto;
    overflow-y: hidden;
  }

  .right-top-content-container {
    flex: 1; /* Allow the content to expand */
    min-height: auto;
  }

  .right-bottom-row {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px auto;
  }

  .right-bottom-col-left {
    flex: 1;
    justify-content: flex-start;
    padding: 0;
  }

  .right-bottom-col-right {
    flex: 1;
    justify-content: flex-end;
    padding: 0;
  }
}

/* SideNavBarUserArea.css */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  max-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  width: inherit;
  display: flex;
  flex-direction: column;
}

.sidebar-up-space {
  height: 120px;
}

.split-screen-left .sidebar {
  position: static;
  padding: 0;
  box-shadow: none;
  min-height: 100%;
}

div.sidebar .nav-item {
  flex-direction: column;
  display: flex;
  justify-content: center; /* For vertical centering */
}

div.sidebar .nav-link {
  color: white;
  padding-left: 30px;
  height: 60px;
  margin: 0;
  display: flex;
  align-items: center;
}

div.sidebar .nav-link:hover {
  color: var(--title_green_color);
}

div.sidebar .nav-link.active {
  color: white;
  background-color: var(--btn_green_dark_color);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

/************************* GLOBAL VARIABLES *************************/
:root {
  /* COLOR VARIABLES */
  --title_blue_color: #0b1331;
  --title_green_color: #58b79f;
  --light_blue_color: #def2f4;
  --light_green_color: #e3ffe7;
  --btn_blue_color: #5077fc;
  --btn_blue_dark_color: #375ee3;
  --btn_blue_light_color: #375fe329;
  --btn_green_color: #58b79f;
  --btn_green_dark_color: #49aa92;
  --btn_green_dark2_color: #2a8f78;
  --btn_green_light_color: #49aa921a;
  --btn_black_color: #000000;
  --btn_black_dark_color: #333333;
  --btn_black_light_color: #33333329;
  --btn_gray_color: #808080a2;
  --btn_gray_dark_color: #6868687b;
  --btn_gray_light_color: #9a9a9a1e;
  --footer_bkg_color: #191d24;
  --footer_more_posts_color: #989da5;
  --field_disabled_text_color: #989da5;
  --field_disabled_bkg_color: rgba(211, 211, 211, 0.167);

  /* NAVBAR VARIABLES */
  --navbar-height: 50px;
  --navbar_top_bottom_padding: 8px;
}

/**********************************************************/

/************************* GLOBAL *************************/
h1 {
  color: var(--title_blue_color);
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  font-size: 4.5em !important;
  margin-bottom: 0 !important;
}

h2 {
  color: var(--title_blue_color);
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  font-size: 3em;
  margin: 4vh 0;
}

.h2-secondary {
  color: var(--title_green_color);
}

h3 {
  color: var(--title_blue_color);
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  font-size: 1.6em;
  text-align: left;
}

.h3-secondary {
  color: var(--title_green_color);
}

h4 {
  color: var(--title_blue_color);
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  font-size: 1.3em !important;
  text-align: center;
}

h5 {
  color: var(--title_blue_color);
  font-family: "Poppins", sans-serif;
  font-size: 0.9em;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: regular;
  margin-bottom: 0;
}

pre {
  font-family: "Poppins", sans-serif !important;
  font-weight: regular !important;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

.colored-a {
  color: var(--btn_green_dark_color) !important;
}

/* Listed numbers */
ol {
  padding-left: 2em;
}
ol li {
  padding-left: 1em;
}

.colored-section {
  padding: 10vh 10vw;
  background-image: linear-gradient(
    to right,
    var(--light_green_color),
    var(--light_blue_color)
  );
}

.content-section {
  padding: 10vh 10vw;
}

.content-p {
  font-family: "Poppins", sans-serif !important;
  color: var(--title_blue_color);
  font-size: 1.1em !important;
}

.title-section {
  padding-bottom: 30px;
}

.title-upper-space {
  padding-top: 40px;
}

.justify-content {
  text-align: justify;
  text-justify: inter-word;
}

.centered-text {
  text-align: center;
}

.link-style {
  color: black;
  text-decoration: none;
}

.link-style:hover {
  color: black;
  text-decoration: none;
}

/* Remove gutters */
.no-gutters {
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}

/* SMARTPHONES --> Reduce image size */
@media (max-width: 481px) {
  h1 {
    font-size: 2.5em !important;
  }

  h2 {
    font-size: 1.6em !important;
  }

  h3 {
    font-size: 1.2em !important;
  }

  h4 {
    font-size: 0.9em !important;
  }

  h5 {
    font-size: 0.8em !important;
  }

  p {
    font-size: 0.8em !important;
  }

  pre {
    font-size: 0.8em !important;
  }
}

/**********************************************************/

/********************** LANDING PAGE **********************/
.colored-section.title-section {
  position: relative;
  overflow: hidden; /* Used to crop images */
}

.title-text-col {
  padding-top: 20vh;
  padding-left: 10vw;
  min-height: 88vh;
  z-index: 2; /* Used to set this column on top of images */
}

.title-img-col {
  height: 80vh;
  z-index: 1;
}

.title-p-group {
  width: 65%;
  margin: 40px 0;
  line-height: 1.8 !important;
}

.title-p {
  color: #5f5f67;
  font-size: 1.3em;
  margin-bottom: 0;
}

.title-img-ireland {
  height: 90vh;
  position: absolute;
  right: 20vw;
  bottom: 2vh;
  opacity: 0.5;
}

.title-img {
  height: 80vh;
  position: absolute;
  right: 0vw;
  bottom: 0;
  margin-right: -180px; /* Crop image */
}

.vector-div {
  position: relative;
}

.vector-txt {
  top: 0;
  left: 0;
  position: relative;
  z-index: 1;
}

.vector-img {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 0;
}

.vector-inline {
  display: inline-block;
}

.vector-img-h1 {
  bottom: -15px;
}
.vector-img-h2 {
  bottom: -5px;
}

/* SMARTPHONES --> Reduce image size */
@media (max-width: 481px) {
  .title-text-col {
    padding-top: 10vh;
    height: 10vh;
    min-height: 30vh;
  }

  .title-img-col {
    padding-top: 0;
    height: 60vh;
  }

  .title-p-group {
    width: 100%;
  }

  .title-img-ireland {
    height: 55vh;
  }

  .title-img {
    height: 50vh;
    margin-right: -140px; /* Crop image */
  }

  .vector-img-h1 {
    bottom: -10px;
  }
  .vector-img-h2 {
    bottom: -5px;
  }
}

/**********************************************************/

/*************** DEMANDED ENGINEERS SECTION ***************/
.dem-eng-group {
  background-image: linear-gradient(
    to right,
    var(--light_green_color),
    var(--light_blue_color)
  );
  border-radius: 25px;
  padding: 2rem 3rem;
  margin-top: 40px;
}

.dem-eng-box-title {
  margin-bottom: 2rem;
}

.dem-eng-card-col {
  padding: 1rem;
}

.dem-eng-card {
  background-color: white;
  border-radius: 25px;
  /*margin: 2rem 1rem;*/
  padding: 2rem 0;
  text-align: center;
}

.dem-eng-card-title {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;
}

.dem-eng-icon {
  color: var(--title_green_color);
  margin: 20px 0 20px 0;
}

/* SMARTPHONES --> Reduce image size */
@media (max-width: 481px) {
  .dem-eng-group {
    padding: 2rem 2rem;
  }
}

/**********************************************************/

/**************** MOVE TO IRELAND SECTION *****************/
.mov-to-irl-card {
  background-color: white;
  border-radius: 25px;
  /*margin: 2rem 1rem;*/
  padding: 0.8rem 0.8rem 0.1rem 0.8rem;
  margin: 5% 15%;
  text-align: center;
}

.mov-to-irl-img {
  border-radius: 20px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Used to zoom and not deform image */
  height: 200px;
  width: 300px;

  overflow: hidden;
}

.hover-zoom {
  transition: all 0.3s ease 0s;
}
.hover-zoom:hover {
  transform: scale(1.1);
}

.mov-to-irl-card-text-div {
  margin-top: 15px;
  padding-left: 3%;
}

.mov-to-irl-card-title {
  font-weight: 600;
  font-size: 0.9em;
  text-align: left;
}

.mov-to-irl-card-sec-text {
  font-weight: regular;
  font-size: 0.7em;
  text-align: left;
}

/* Container holding the image and the text */
.mov-to-irl-img-container {
  position: relative;
}

/* Top right text */
.mov-to-irl-img-text-block {
  border-radius: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 4px 15px;
}

.mov-to-irl-img-text-block-white {
  background-color: white;
  color: black;
  opacity: 0.75;
}

.mov-to-irl-img-text-block-green {
  background-color: var(--btn_green_dark_color);
  color: white;
  opacity: 0.9;
}

.mov-to-irl-img-text-block-blue {
  background-color: var(--btn_blue_dark_color);
  color: white;
  opacity: 0.9;
}

.mov-to-irl-card-img-text {
  font-weight: 400;
  font-size: 0.7em;
  text-align: center;
  padding: 0;
  margin: 0;
}

.mov-to-irl-btn-div {
  max-width: 100%;
  text-align: center;
  padding-top: 2rem;
  margin-bottom: 40px;
}

/* SMARTPHONES --> Reduce image size */
@media (max-width: 481px) {
  .mov-to-irl-card {
    margin: 5% 5%;
  }
}

/**********************************************************/

/************************ ABOUT US ************************/

.about-us-img-circle {
  border-radius: 50%;
  margin-bottom: 40px;
  width: 150px;
  height: 150px;
  background-position: center;
  display: block;
  margin: auto;
  overflow: hidden;
  background-size: 100%; /* Initial background size */
  transition: background-size 2s; /* Transition setup */
}

.about-us-img-circle.zoomed {
  background-size: 200%;
}

/**********************************************************/

/************************* FOOTER *************************/

.footer-section {
  padding: 5vh 10vw 1vh 10vw;
  background-color: var(--footer_bkg_color);
}

.footer-line {
  height: 0px;
  border: none;
  border-top: 1px solid white;
  margin-top: 5vh;
  opacity: 1;
}

.footer-title {
  color: white;
  font-weight: 600;
  margin-top: 30px;
}

.footer-text {
  color: white;
  font-weight: 300;
  margin-top: 20px;
}

.footer-text-link:hover {
  color: var(--btn_green_color);
}

/* Remove underline from anchor tag */
.footer-a {
  text-decoration: none;
}

.footer-p {
  color: white;
  font-weight: 200;
  font-size: 0.9em;
}

.footer-copyright-col {
  padding-top: 0;
  text-align: left;
}

.footer-icon-col {
  text-align: right;
}

.footer-icon {
  color: white;
}

.footer-icon:hover {
  color: var(--btn_green_color);
}

/* SMARTPHONES --> Reduce image size */
@media (max-width: 481px) {
  .footer-copyright-col {
    padding-top: 5px;
  }
}
/**********************************************************/

/************************ POST TPL ************************/

.post-subtitle {
  font-weight: 600 !important;
  margin-top: 40px !important;
  margin-bottom: 30px !important;
}

.post-tpl-div {
  padding-top: 120px;
}

.p-more-posts {
  font-size: 0.9em;
  color: var(--footer_more_posts_color);
  text-decoration: none;
}

.p-more-posts:hover {
  color: gray;
  text-decoration: none;
}

.post-tpl-content {
  padding-top: 30px;
}

.post-tpl-h5 {
  margin: 20px 0;
  font-size: 0.8em;
  font-weight: 400;
}

.post-bold-text {
  font-weight: 600;
}

.post-tip-box {
  background-image: linear-gradient(
    to right,
    var(--light_green_color),
    var(--light_blue_color)
  );
  border-radius: 25px;
  padding: 2rem 3rem;
  margin-top: 40px;
}

.post-tip-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.post-tip-title {
  color: var(--btn_green_dark_color);
  text-align: left;
  font-style: italic;
  display: inline-block;
}

.post-tip-title-text {
  font-weight: 500;
  display: inline-block;
}

.post-tip-text {
  font-style: italic;
  color: var(--title_blue_color);
  font-size: 1.1em;
}

/**********************************************************/

/********************** USER SECTION **********************/

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

/**********************************************************/

/************************* SIGN IN ************************/

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--light_blue_color);
}

.login-container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 400px;
}

.login-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--title_blue_color);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-options {
  margin-top: 20px;
  text-align: center;
}

.login-link {
  color: var(--title_blue_color);
  text-decoration: none;
  margin: 0 8px;
}

.login-separator {
  color: #999;
}

.or-separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.or-line {
  flex-grow: 1;
  height: 1px;
  background-color: #cccccc;
}

.or-text {
  color: #808080;
  margin: 0 10px;
}

.or-line:before,
.or-line:after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: #cccccc;
}

.or-line:before {
  margin-right: 10px;
}

.or-line:after {
  margin-left: 10px;
}

.password-field {
  position: relative;
  width: 100%;
}

.login-input {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  margin-top: -0.5rem; /* Adjust the margin as needed */
  right: 10px;
}

/**********************************************************/

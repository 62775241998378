/************************* NAVBAR *************************/

.navbar {
  background-color: white !important;
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.1);
}

/* change the background color */
.navbar-custom {
  background-color: #4433cc;
}

.navbar a {
  font-family: "Poppins", sans-serif !important;
  color: var(--footer_bkg_color);
}

.navbar a:hover {
  color: var(--title_green_color) !important;
}

.navbar-container {
  margin: 0 !important;
  padding: var(--navbar_top_bottom_padding) 10px !important;
}

.navbar-collapse {
  background-color: white;
}

/* At the right side of nav-bar logo */
.navbar-brand {
  margin-right: 50px !important;
  margin-left: 20px !important;
}

/* Leave space on the side of each nav-bar link */
.nav-link {
  margin: 0 20px;
}

.navbar-logo {
  width: 160px;
}

.navbar-mobile-login-div {
  margin-left: 20px;
  margin-top: 20px;
}
/**********************************************************/

.ua-up-space {
  height: 20px;
}

.ua-container {
  margin-top: 50px;
}

.ua-content-p {
  font-weight: 300;
  font-size: 1em;
  color: gray;
}

.ua-content-div {
  margin-left: 30px;
}

.ua-first-title {
  margin-top: 10px;
}

.ua-title {
  margin-top: 60px;
}

.ua-edit-button-div {
  margin-top: 60px;
  text-align: right;
  margin-right: 70px;
}

.align-bottom {
  display: grid;
  align-content: end;
}

.title-blocked {
  color: lightgray !important;
}

.subform-title-p {
  margin-top: 15px;
  font-weight: 500;
  font-size: 1.4em;
  color: var(--title_green_color);
  margin-bottom: 10px;
}

.subform-text-p {
  margin-top: 15px;
  font-weight: 200;
  font-size: 1em;
  color: gray;
  margin-top: 0;
  margin-bottom: 6px;
  margin-left: 20px;
}

.subform-text-pre {
  margin-top: 15px;
  font-weight: 200;
  font-size: 1em;
  color: gray;
  margin-top: 0;
  margin-bottom: 6px;
  margin-left: 50px;
}

.subform-text-spaced-p {
  margin-top: 15px;
  font-weight: 200;
  font-size: 1em;
  color: gray;
  margin-top: 0;
  margin-bottom: 10px;
}

.subform-buttons-row {
  margin-top: 40px;
  margin-right: 0;
  margin-left: 0;
}

.subform-btn-col-left {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.subform-btn-col-right {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.subform-btn-col-center {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.subform-add-button-div {
  margin-bottom: 40px;
}

.right-top-content-container {
  position: relative; /* Establishing positioning context */
}

.ua-mobile-form-div {
  padding: 0 20px;
}

.ua-feedback-message {
  position: absolute;
  bottom: 5px; /* Adjust this value as needed to position above the SeparationLine */
  left: 0;
  right: 0;
  color: red;
  text-align: center;
  animation: fadeInOut 3s;
  z-index: 1; /* To ensure it's above other elements */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Mobile Version Adjustments */
@media (max-width: 768px) {
  /* Adjust breakpoint as needed */
  .subform-title-p {
    font-size: 1.1em !important;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .subform-buttons-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subform-btn-col-left {
    flex: 0 1 40%;
    display: flex;
    justify-content: flex-start;
    padding: 5px;
  }

  .subform-btn-col-right {
    flex: 0 1 60%;
    display: flex;
    justify-content: flex-end; /* Aligns buttons to the right */
    padding: 5px;
  }

  .subform-btn-col-right .row {
    width: 100%;
    display: flex;
    justify-content: flex-end; /* Aligns buttons to the right */
    flex-wrap: nowrap; /* Prevents wrapping of buttons */
  }

  .subform-btn-col-right .col-md-6 {
    flex: 1; /* Equal space for Cancel and Submit buttons */
    display: flex;
    justify-content: center; /* Centering buttons inside their container */
    padding: 0; /* Removes padding */
    max-width: none; /* Overrides Bootstrap's max-width */
  }

  .subform-btn-col-right .EIIButton {
    margin: 0 2px; /* Reduces margin between buttons */
    padding: 5px; /* Adjust button padding if needed */
  }
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

button:disabled .throbber {
  opacity: 1 !important;
}

.btn-primary {
  font-family: "Poppins", sans-serif;
  font-weight: regular;
  font-size: 1.2em;
}

.btn-big {
  width: 10rem;
  height: 3.5rem;
}

.btn-small {
  min-width: 5rem;
  height: 2.5rem;
}

.btn-icon-size {
  min-width: 4rem;
  height: 2.5rem;
}

.btn-full-width {
  width: 100% !important;
}

.btn-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-column-icon {
  flex: 0 0 25%; /* Set the width to 25% */
}

.btn-column-text {
  flex: 1; /* Occupy the remaining width */
}

.btn-icon {
  margin-right: 0; /* Adjust the margin as needed */
}

.btn-icon-left .btn-icon {
  margin-right: 8px;
}

.btn-icon-right .btn-icon {
  margin-left: 8px; /* Space between text and icon */
}

.btn-icon-center .btn-icon {
  display: block;
  margin: 0; /* Center the icon */
  text-align: center;
}

.custom-btn-blue {
  background-color: var(--btn_blue_color) !important;
  border-color: var(--btn_blue_color) !important;
}

.custom-btn-blue:hover {
  background-color: var(--btn_blue_dark_color) !important;
  border-color: var(--btn_blue_dark_color) !important;
}

.custom-btn-blue-transparent {
  background-color: transparent !important;
  border-color: var(--btn_blue_color) !important;
  color: var(--btn_blue_color) !important;
  border-width: 2px !important;
}

.custom-btn-blue-transparent:hover {
  background-color: var(--btn_blue_light_color) !important;
  border-color: var(--btn_blue_dark_color) !important;
  color: var(--btn_blue_dark_color) !important;
}

.custom-btn-green {
  background-color: var(--btn_green_color) !important;
  border-color: var(--btn_green_color) !important;
}
.custom-btn-green:hover {
  background-color: var(--btn_green_dark_color) !important;
  border-color: var(--btn_green_dark_color) !important;
}

.custom-btn-green-transparent {
  background-color: transparent !important;
  border-color: var(--btn_green_color) !important;
  color: var(--btn_green_color) !important;
  border-width: 2px !important;
}

.custom-btn-green-transparent:hover {
  background-color: var(--btn_green_light_color) !important;
  border-color: var(--btn_green_dark_color) !important;
  border-color: var(--btn_green_dark_color) !important;
}

.custom-btn-black {
  background-color: var(--btn_black_color) !important;
  border-color: var(--btn_black_color) !important;
  color: white !important;
}

.custom-btn-black:hover {
  background-color: var(--btn_black_dark_color) !important;
  border-color: var(--btn_black_dark_color) !important;
}

.custom-btn-black-transparent {
  background-color: transparent !important;
  border-color: var(--btn_black_color) !important;
  color: var(--btn_black_color) !important;
  border-width: 2px !important;
}

.custom-btn-black-transparent:hover {
  background-color: var(--btn_black_light_color) !important;
  border-color: var(--btn_black_dark_color) !important;
}

.custom-btn-gray {
  background-color: var(--btn_gray_color) !important;
  border-color: var(--btn_gray_color) !important;
  color: white !important;
}

.custom-btn-gray:hover {
  background-color: var(--btn_gray_dark_color) !important;
  border-color: var(--btn_gray_dark_color) !important;
}

.custom-btn-gray-transparent {
  background-color: transparent !important;
  border-color: var(--btn_gray_color) !important;
  color: var(--btn_gray_color) !important;
  border-width: 2px !important;
}

.custom-btn-gray-transparent:hover {
  background-color: var(--btn_gray_light_color) !important;
  border-color: var(--btn_gray_dark_color) !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.throbber {
  display: flex;
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.justify-left {
  justify-content: left;
}
.justify-right {
  justify-content: right;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: var(--btn_green_dark2_color); /* Default color is black */
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out both;
  margin: 0 4px;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

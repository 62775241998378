/* UserVerificationSent.css */

.title-section-uv {
  min-height: 70vh;
}

.email-icon {
  font-size: 3rem;
  margin-top: 100px;
  margin-bottom: 50px;
  color: var(--title_blue_color);
}

.verif-title-text {
  text-align: center;
  color: var(--title_blue_color);
}

.center-text {
  text-align: center;
  margin-top: 1rem;
}

.separation-line {
  border: 1px solid #ccc;
  margin: 30px auto 60px auto;
  width: 50%;
}

.main-text {
  font-size: 20px;
  margin-bottom: 60px;
  color: var(--title_blue_color);
}

/* Media Query for Phones */
@media (max-width: 480px) {
  .email-icon {
    font-size: 2rem; /* Adjust the font size for smaller screens */
  }

  .separation-line,
  .main-text {
    margin: 20px auto; /* Adjust the margin values as needed */
  }
}

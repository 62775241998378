.circle-profile {
  width: 50px; /* Adjust the width to your desired size */
  height: 50px; /* Adjust the height to your desired size */
  background-color: var(--btn_green_dark_color);
  color: white; /* Text color inside the circle */
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  margin-right: 40px;
}

/* CompletionRectangles.css */
.completion-rectangles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 2em;
  width: 100%;
}

.completion-rectangles {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rectangle {
  flex: 1;
  height: 10px;
  margin: 0 7px;
  border-radius: 5px;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f4f4f4;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cookie-banner p {
  margin-bottom: 10px;
}

.cookie-banner a {
  color: #007bff;
  text-decoration: none;
}

.cookie-banner .cookie-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.cookie-banner .cookie-button-wrapper {
  margin-bottom: 10px; /* Space between buttons */
}

.cookie-banner .cookie-button-wrapper:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .cookie-banner .cookie-buttons {
    flex-direction: row;
    justify-content: center;
  }

  .cookie-banner .cookie-button-wrapper {
    margin-right: 10px; /* Space between buttons for desktop */
    margin-bottom: 0;
  }

  .cookie-banner .cookie-button-wrapper:last-child {
    margin-right: 0;
  }
}

/* TopNavbarUserArea.css */

.top-navbar-user-area {
  background-color: white;
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.1);
}

.top-navbar-user-area .navbar-logo {
  width: 160px;
}

.top-navbar-user-area .nav-link {
  color: var(--footer_bkg_color);
  margin: 0 20px;
}

/* Adding white space above the first nav item */
.top-navbar-user-area .nav-link:first-child {
  margin-top: 20px; /* Adjust the value as needed for desired spacing */
}

.top-navbar-user-area .nav-link:hover {
  color: var(--title_green_color) !important;
}

.top-navbar-user-area .navbar-brand {
  margin-right: 50px;
  margin-left: 20px;
}

.top-navbar-user-area .navbar-collapse {
  background-color: white;
}

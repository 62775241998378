/* FormField.css */

/* General Styles */

.formfield-p {
  margin-top: 25px;
  font-weight: 500;
  font-size: 1.1em;
}

.formfield-input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
}

.formfield-input-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 40px;
}

.formfield-input-wrapper.required {
  border: 1px solid red;
  border-radius: 5px;
}

.formfield-input:focus {
  outline: none;
  border: 2px solid var(--btn_green_color);
  border-radius: 5px;
}

.formfield-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  height: auto !important;
}

.formfield-checkbox-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.formfield-checkbox {
  margin-right: 10px; /* Add space between checkbox and label */
}

.checkbox-label {
  display: flex;
  align-items: center; /* Centers the label vertically */
  justify-content: flex-start; /* Aligns the label to the left */
  margin-left: 10px; /* Adds some space between the checkbox and the label */
}

.required-asterisk {
  color: red;
}

.alert-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: red;
}

/* Tel Input Styles */

.tel-input-container {
  display: flex;
  flex-direction: row; /* Arrange children in a row on desktop */
  align-items: center; /* Align children vertically in the middle */
  justify-content: space-between; /* Add space between children */
  margin-bottom: 15px;
}

.select-input {
  width: 30%;
  margin-right: 10px;
}

.phone-country-input {
  width: calc(30% - 10px); /* Take up half the width, minus a small margin */
}

.phone-num-input {
  width: 70%; /* Take up half the width, minus a small margin */
}

.title-blocked {
  color: lightgray !important;
}

/* Field Wrapper Styles */

.field-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.field-wrapper.required {
  border: 1px solid red;
  border-radius: 5px;
}

.field-wrapper.single-row {
  flex-direction: row;
  align-items: center; /* Vertically center elements in row direction */
}

.field-wrapper label {
  display: flex;
  align-items: center;
  margin: 0.2em;
  margin-left: 1em;
}

.field-wrapper label input {
  margin-right: 0.5em;
}

/* File Input Styles */

/* Hide the default file input button */
.formfield-input.file-input {
  display: none;
}

.custom-file-input {
  display: inline-block;
  padding: 10px 15px;
  color: white; /* Change this to your desired text color */
  border-radius: 5px;
  cursor: pointer;
}

.selected-file-name {
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style the custom file input label text */
.custom-file-input::after {
  content: "Choose File"; /* Change this text to your desired label */
}

/* Optional: Add some hover effect */
.custom-file-input:hover {
  background-color: #007bff; /* Change this to your desired hover background color */
}

/* Autocomplete */

/* Apply the same font-family and text style to the Autocomplete input */
.MuiAutocomplete-root .MuiInputBase-root,
.MuiAutocomplete-root .MuiOutlinedInput-input {
  font-family: "Poppins", sans-serif; /* Replace with your desired font */
  font-weight: 300; /* Adjust font weight as needed */
  font-size: 1em; /* Adjust font size as needed */
}

/* Apply the same font-family and text style to Autocomplete options */
.MuiAutocomplete-option {
  font-family: "Poppins", sans-serif; /* Replace with your desired font */
  font-weight: 300; /* Adjust font weight as needed */
  font-size: 1em; /* Adjust font size as needed */
}

.MuiInputBase-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 1px solid #ddd;
}
/* Remove border when focused */
.MuiInputBase-root.Mui-focused {
  border: none;
}

/* Remove the default border and outline */
.custom-autocomplete .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

/* Add styles to change the border color when focused */
.custom-autocomplete .MuiOutlinedInput-root.Mui-focused {
  border-color: var(--btn_green_color) !important;
  box-shadow: 0 0 0 2px var(--btn_green_color) !important;
}

.MuiAutocomplete-option {
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Disabled styles for input, select, and textarea */
.formfield-input:disabled,
.select-input:disabled,
textarea:disabled {
  background-color: var(
    --field_disabled_bkg_color
  ); /* Use your preferred disabled background color here */
  color: var(
    --field_disabled_text_color
  ); /* Use your preferred disabled text color here */
  cursor: not-allowed;
}

/* If you are using wrapper divs like '.formfield-input-wrapper' for styling disabled state */
.formfield-input-wrapper.disabled .formfield-input,
.formfield-input-wrapper.disabled .select-input {
  background-color: var(--field_disabled_bkg_color);
  color: var(--field_disabled_text_color);
  cursor: not-allowed;
}

/* Disabled styles for any custom components or elements */
.custom-autocomplete.disabled .MuiInputBase-root,
.custom-file-input.disabled + label {
  /* Assuming you're using a label to style the file input */
  background-color: var(--field_disabled_bkg_color);
  color: var(--field_disabled_text_color);
  cursor: not-allowed;
}

/* Material-UI specific disabled styles */
.MuiAutocomplete-root .MuiInputBase-root.Mui-disabled,
.MuiInputBase-root.Mui-disabled {
  background-color: var(
    --field_disabled_bkg_color
  ) !important; /* !important might be needed to override inline styles */
  color: var(--field_disabled_text_color) !important;
}

/* Ensuring the disabled class works for elements with classes like 'phone-num-input' */
.phone-num-input.disabled,
.phone-country-input.disabled,
.select-input.disabled {
  background-color: var(
    --field_disabled_bkg_color
  ); /* Use your preferred disabled background color here */
  color: var(
    --field_disabled_text_color
  ); /* Use your preferred disabled text color here */
  cursor: not-allowed;
}

/* Targeting the control when disabled */
.select__control--is-disabled {
  background-color: var(
    --field_disabled_bkg_color
  ) !important; /* Disabled background color */
  color: var(--field_disabled_text_color) !important; /* Disabled text color */
}

/* If you want to style the placeholder when disabled */
.select__placeholder--is-disabled {
  color: var(--field_disabled_text_color) !important;
}

/* To style the single value when disabled */
.select__single-value--is-disabled {
  color: var(--field_disabled_text_color) !important;
}

.loading-field {
  height: 100%;
  padding-left: 10px;
}

/* On mobile */
@media (max-width: 768px) {
  .tel-input-container {
    flex-direction: column; /* Arrange children in a column on mobile */
  }

  .select-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .phone-num-input {
    width: 100%;
  }

  .phone-country-input {
    width: 100%;
  }

  .formfield-input-wrapper {
    font-size: 0.8em;
  }
}
